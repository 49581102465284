import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Divider from '@mui/material/Divider'
import InputQuestion from '../Comparison/InputQuestion'
import OptionsQuestion from '../Comparison/OptionsQuestion'
import DropdownQuestion from '../Comparison/DropdownQuestion'
import { electricityProviderOptions, regionOptions, tariffTypeOptions, paymentMethodOptions,  } from '../../config/constants/Constants'
import { getTariffStartOptions } from '../../config/helpers/Comparison'

export default function ElectricityForm(props) {
    const site = props.site
    const data = props.data
    const allPlans = props.plans

    const [plans, setPlans] = useState(allPlans)
    const [planPaymentMethods, setPlanPaymentMethods] = useState([])
    const [planOnlineBilling, setPlanOnlineBilling] = useState([])
    const [filtering, setFiltering] = useState(false)
    const [hideRegistrationDate, setHideRegistrationDate] = useState(false)

    function updateSupplier(supplier) {
        props.onUpdate({ supplier: supplier })
        filterPlans(supplier, data.region, data.tariffType, data.paymentMethod, data.onlineBilling, 'supplier')
    }

    function updateRegion(region) {
        props.onUpdate({ region: region })
        filterPlans(data.supplier, region, data.tariffType, data.paymentMethod, data.onlineBilling, 'region')
    }

    function updateTariffType(tariffType) {
        props.onUpdate({ tariffType: tariffType })
        if (tariffType === 'Smart') {
            props.updateIncludeSmartPlans('Yes')
        }
        filterPlans(data.supplier, data.region, tariffType, data.paymentMethod, data.onlineBilling, 'tariffType')
    }

    function updatePaymentMethod(paymentMethod) {
        props.onUpdate({ paymentMethod: paymentMethod })
        if (paymentMethod === 'Pay as You Go') {
            props.onUpdate({ onlineBilling: 'Yes' })
        }
        filterPlans(data.supplier, data.region, data.tariffType, paymentMethod, 'Yes', 'paymentMethod')
    }

    function updateOnlineBilling(onlineBilling) {
        props.onUpdate({ onlineBilling: onlineBilling })
        filterPlans(data.supplier, data.region, data.tariffType, data.paymentMethod, onlineBilling, 'onlineBilling')
    }

    function filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, question) {
        setFiltering(true)
        // Temporarily reset paymentMethod and onlineBilling if changing criteria could affect them
        let tempPaymentMethod = paymentMethod
        let tempOnlineBilling = onlineBilling
    
        // Reset paymentMethod and onlineBilling if the question impacts options
        if (['supplier', 'region', 'tariffType'].includes(question)) {
            tempPaymentMethod = ''
            tempOnlineBilling = ''
        }
    
        // Get available options for paymentMethod and onlineBilling based only on supplier, region, and tariffType
        const baseFilteredPlans = allPlans.filter(plan => {
            return (!supplier || plan.Supplier.toLowerCase().trim() === supplier.toLowerCase().trim()) &&
                   (!region || plan.Region.toLowerCase().trim() === region.toLowerCase().trim()) &&
                   (!tariffType || plan['Tariff Type'].toLowerCase().trim() === tariffType.toLowerCase().trim())
        })
    
        const availablePaymentMethods = [
            ...new Set(baseFilteredPlans.flatMap(plan => plan['Payment Type'].split(', ').map(type => type.trim())))
        ]
        const availableOnlineBillingOptions = [
            ...new Set(baseFilteredPlans.flatMap(plan => {
                return plan['Online Billing'].split('/').map(type => {
                    const trimmedType = type.trim()
                    return trimmedType === 'Y' ? 'Yes' : trimmedType === 'N' ? 'No' : null;
                }).filter(Boolean) // Exclude null values
            }))
        ]
    
        // Initialize onlineBilling if empty
        if (!tempOnlineBilling && availableOnlineBillingOptions.length > 0) {
            tempOnlineBilling = availableOnlineBillingOptions[0] // Default to first available option
        }
        // Initialize paymentMethod if empty 
        if (!tempPaymentMethod && availablePaymentMethods.length > 0) {
            tempPaymentMethod = availablePaymentMethods[0] // Default to first available option
        }
    
        // Map the front-end 'Yes'/'No' to back-end 'Y'/'N'
        const onlineBillingValue = tempOnlineBilling === 'Yes' ? 'Y' : tempOnlineBilling === 'No' ? 'N' : ''
    
        // Filter plans based on all selected criteria
        const filteredPlans = allPlans.filter(plan => {
            return (!supplier || plan.Supplier.toLowerCase().trim() === supplier.toLowerCase().trim()) &&
                   (!region || plan.Region.toLowerCase().trim() === region.toLowerCase().trim()) &&
                   (!tariffType || plan['Tariff Type'].toLowerCase().trim() === tariffType.toLowerCase().trim()) &&
                   (!tempPaymentMethod || plan['Payment Type'].split(',').map(type => type.trim().toLowerCase()).includes(tempPaymentMethod.toLowerCase().trim())) &&
                   (!onlineBillingValue || plan['Online Billing'].split('/').some(type => {
                       const trimmedType = type.trim()
                       return trimmedType === onlineBillingValue || trimmedType === 'Y/N'
                   }))
        })
    
        console.log('Filtered plans:', filteredPlans)
        setPlans(filteredPlans)
    
        // Update available options for paymentMethod and onlineBilling
        setPlanPaymentMethods(availablePaymentMethods)
        setPlanOnlineBilling(availableOnlineBillingOptions)
    
        // Update states
        props.onUpdate({ paymentMethod: tempPaymentMethod })
        props.onUpdate({ onlineBilling: tempOnlineBilling })
        setFiltering(false)
    }        

    function updatePlanId(id) {
        if (id === 'custom') {
            props.onUpdate({ planId: 'custom' })
        }
        else {
            const selectedPlan = plans.find(plan => plan['ID'] === id)
            console.log('selected plan: ', selectedPlan)
            props.onUpdate({ planId: selectedPlan['ID'] })
            
            let options = getTariffStartOptions(selectedPlan['Earliest Date'], selectedPlan['End Date']) || []
            console.log('options: ', options)
            props.onUpdate({ tariffStart: options.length > 0 ? options[0].value : '' })
            props.onUpdate({ tariffStartOptions: options || [] })
            
            if (selectedPlan['Contract'].toLowerCase().trim() === 'no fixed term') {
                setHideRegistrationDate(true)
            }
            else {
                setHideRegistrationDate(false)
            }
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()} className={props.className}>
            <Grid container justifyContent='center' className='question-container'>
                <Grid xs={10} style={{marginBottom: '2rem'}}>
                    <Divider className='calc-section-divider'><h2>Your Electricity</h2></Divider>
                </Grid>
                <Grid xs={10} className='question'>
                    <OptionsQuestion
                        question='Select your current electricity supplier...'
                        options={electricityProviderOptions}
                        answer={data.supplier}
                        updateAnswer={(supplier) => updateSupplier(supplier)}
                        site={site}
                        xs={6}
                        sm={4}
                    />
                </Grid>
                {data.supplier &&
                    <>
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='What is your electricity supply region?'
                                options={regionOptions}
                                answer={data.region}
                                updateAnswer={(region) => updateRegion(region)}
                                site={site}
                                xs={12}
                                sm={6}
                            />
                        </Grid>
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='What type of electricity tariff do you currently have?'
                                options={tariffTypeOptions}
                                answer={data.tariffType}
                                updateAnswer={(tariffType) => updateTariffType(tariffType)}
                                site={site}
                                xs={12}
                                sm={4}
                            />
                        </Grid>
                        {data.tariffType === 'Nightsaver' &&
                        <>
                            <Grid xs={10} className='question'>
                                <OptionsQuestion
                                    question='Do you know what percentage of electricity you use during Nightsaver hours?'
                                    options={
                                        [
                                            {value: 'Yes', label: 'Yes', disabled: false},
                                            {value: 'No, use National Average', label: 'No, use National Average', disabled: false}
                                        ]
                                    }
                                    answer={data.nightsaverUsage}
                                    updateAnswer={(nightsaverUsage) => props.onUpdate({ nightsaverUsage: nightsaverUsage })}
                                    site={site}
                                    xs={12}
                                    sm={6}
                                />
                            </Grid>
                            {data.nightsaverUsage === 'Yes' &&
                                <Grid xs={10} className='question'>
                                    <InputQuestion
                                        question='What percentage of your electricity is during Nightsaver hours?'
                                        type='number'
                                        placeholder='38 (National Average)'
                                        answer={data.nightsaverUsageExact}
                                        updateAnswer={(nightsaverUsageExact) => props.onUpdate({ nightsaverUsageExact: nightsaverUsageExact })}
                                        site={site}
                                    />
                                </Grid>
                            }
                        </>
                        }
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='How do you pay your electricity bills?'
                                options={paymentMethodOptions.map(option => ({
                                    ...option,
                                    disabled: planPaymentMethods.length > 0 ? !planPaymentMethods.includes(option.value) : false
                                }))}
                                answer={data.paymentMethod}
                                updateAnswer={(paymentMethod) => updatePaymentMethod(paymentMethod)}
                                site={site}
                                xs={12}
                                sm={3}
                            />
                        </Grid>
                        {data.paymentMethod !== 'Pay as You Go' &&
                            <Grid xs={10} className='question'>
                                <OptionsQuestion
                                    question='Do you use online billing?'
                                    options={
                                        [
                                            {value: 'Yes', label: 'Yes', disabled: false},
                                            {value: 'No', label: 'No', disabled: false}
                                        ].map(option => ({
                                            ...option,
                                            disabled: planOnlineBilling.length > 0 ? !planOnlineBilling.includes(option.value) : false
                                        }))
                                    }
                                    answer={data.onlineBilling}
                                    updateAnswer={(onlineBilling) => updateOnlineBilling(onlineBilling)}
                                    site={site}
                                    xs={12}
                                    sm={6}
                                />
                            </Grid>
                        }
                        <Grid xs={10} className='question'>
                            <DropdownQuestion
                                question='What is your tariff called?'
                                options={[{ label: 'Enter discount manually', value: 'custom' }, ...plans.map((plan, index) => ({
                                    label: plan['Tariff Name'],
                                    value: plan['ID']
                                }))]}
                                plans={plans}
                                answer={data.planId}
                                updateAnswer={(id) => updatePlanId(id)}
                                disabled={filtering}
                                site={site}
                            />
                        </Grid>
                        {data.planId === 'custom' &&
                            <Grid xs={10} className='question'>
                                <InputQuestion
                                    question='Please enter your current discount'
                                    type='number'
                                    placeholder='20'
                                    answer={data.customDiscount}
                                    updateAnswer={(customDiscount) => props.onUpdate({ customDiscount: customDiscount })}
                                    site={site}
                                />
                            </Grid>
                        }
                        {!hideRegistrationDate &&
                            <Grid xs={10} className='question'>
                                <DropdownQuestion
                                    question='When did you sign up to this tariff?'
                                    options={data.tariffStartOptions || []}
                                    answer={data.tariffStart}
                                    updateAnswer={(tariffStart) => props.onUpdate({ tariffStart: tariffStart })}
                                    site={site}
                                />
                            </Grid>
                        }
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='Do you know how much electricity you use?'
                                options={
                                    [
                                        {value: 'Yes', label: 'Yes', disabled: false},
                                        {value: 'No, use National Average', label: 'No, use National Average', disabled: false}
                                    ]
                                }
                                answer={data.electricityUsage}
                                updateAnswer={(electricityUsage) => props.onUpdate({ electricityUsage: electricityUsage })}
                                site={site}
                                xs={12}
                                sm={6}
                            />
                        </Grid>
                        {data.electricityUsage === 'Yes' &&
                            <Grid xs={10} className='question'>
                                <InputQuestion
                                    question='How much electricity do you user per year? (kWh)'
                                    type='number'
                                    placeholder='4,200 (National Average)'
                                    answer={data.electricityUsageExact}
                                    updateAnswer={(electricityUsageExact) => props.onUpdate({ electricityUsageExact: electricityUsageExact })}
                                    site={site}
                                />
                            </Grid>
                        }
                    </>
                }
            </Grid>
        </form>
    )
}
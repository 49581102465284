import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Slider from '@mui/material/Slider'
import FormLabel from '@mui/material/FormLabel'


export default function SliderQuestion(props) {

    const steps = props.steps
    
    // Convert value to step index
    const valueToIndex = (value) => steps.indexOf(value);

    // Convert step index to value
    const indexToValue = (index) => steps[index];

    function valuetext(value) {
        return `${value} panels`;
    }

    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} sx={{ textAlign: 'left', width: '100%' }}>
            <Grid xs={12}>
                {props.label ? (
                    <label style={{ marginBottom: '1rem' }}>{props.question}</label>
                ) : (
                    <h3 style={{ marginBottom: '1rem' }}>{props.question}</h3>
                )}
            </Grid>
            <Grid xs={12}>
                <Slider
                    value={valueToIndex(props.answer)}
                    onChange={(event, newIndex) => props.updateAnswer(indexToValue(newIndex))}
                    getAriaValueText={valuetext}
                    marks={steps.map((value, index) => ({ value: index, label: value.toString() }))}
                    step={1}
                    min={0}
                    max={steps.length - 1}
                    valueLabelDisplay='auto'
                    valueLabelFormat={(index) => steps[index]}
                />
            </Grid>
        </Grid>
    )
}
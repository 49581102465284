import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import PlanSummary from '../Comparison/PlanSummary'
import Alert from '@mui/material/Alert'
import { formatNumber } from '../../config/helpers/Helper'

export default function DataCaptureForm(props) {
    const site = props.site
    const results = props.results

    const [showPlansWithoutSavings, setShowPlansWithoutSaving] = useState(false)       

    return (
        <div ref={props.ref} className='comparison-results'>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid xs={10} display='flex' justifyContent='center' alignItems='center' className='buttons' style={{textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                    <Button color={site} variant='contained' size='small' onClick={() => props.setStage('calculator')}>Change Plan Details</Button>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={10} style={{textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                    {results.resultsWithSavings.length > 0 ? (
                        <h2>Good news! You can save <span className='savings'>€{formatNumber(results.resultsWithSavings[0]['savings'])}</span> by switching your {props.form.toLowerCase()}</h2>
                    ) : (
                        <h2>Unfortunately we could not find any plans that you can save by switching to. Please ensure you have entered your details correctly.</h2>
                    )}
                </Grid>
                <Grid item xs={10} style={{textAlign: 'center', marginBottom: '1rem'}}>
                    <p>We calculate that your current {props.form.toLowerCase()} costs for the next 12 months are €{formatNumber(results.yourBill * 1.09)} (Inc. VAT at 9.0%)</p>
                </Grid>
                {results.exitFee > 0 &&
                    <Grid item xs={10} style={{textAlign: 'center', marginBottom: '1rem'}}>
                        <Alert severity='warning'>Note: If you switch now you may be subject to an exit fee of €{formatNumber(results.exitFee)}. This exit fee is included in your savings calculations.</Alert>
                    </Grid>
                }
            </Grid>
            {results.resultsWithSavings.length > 0 && 
                <Grid container justifyContent='center' alignItems='center' spacing={2}>
                    <Grid item xs={10}>
                        <p>Showing {results.resultsWithSavings.length} plans with savings</p>
                    </Grid>
                    {results.resultsWithSavings.map((plan) => {
                        return (
                            <Grid item xs={10} key={plan['ID']}>
                                <PlanSummary
                                    form={props.form}
                                    plan={plan}
                                    yourBill={results.yourBill}
                                    includeCashback={results.includeCashback}
                                    site={site}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            }
            {results.resultsWithoutSavings.length > 0 && 
                <Grid container justifyContent='center' alignItems='center' spacing={2}>
                    <Grid item xs={10}>
                        {showPlansWithoutSavings ? (
                            <p>Showing {results.resultsWithoutSavings.length} plans without savings</p>
                        ) : (
                            <p className='show-plans-text' onClick={() => setShowPlansWithoutSaving(true)}>Show {results.resultsWithoutSavings.length} more plans without savings</p>
                        )}
                    </Grid>
                    {showPlansWithoutSavings && results.resultsWithoutSavings.map((plan) => {
                        return (
                            <Grid item xs={10} key={plan['ID']}>
                                <PlanSummary
                                    form={props.form}
                                    plan={plan}
                                    yourBill={results.yourBill}
                                    includeCashback={results.includeCashback}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </div>
    )
}
import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { electricityProviderOptions } from '../../config/constants/Constants'
import { formatNumber } from '../../config/helpers/Helper'

export default function PlanSummary(props) {
    const site = props.site
    const plan = props.plan
    const provider = electricityProviderOptions.find((option) => option.value === plan['Supplier'])
    // Calculate the estimated bill with VAT and savings
    const estimatedBill = formatNumber(plan['estimatedAnnualBill'] * 1.09)
    //const savings = formatNumber((yourBill * 1.09) - (plan['estimatedAnnualBill'] * 1.09))

    return (
        <Grid container spacing={2} className='plan-container' style={{ marginBottom: '1rem' }}>
            {/* Logo and Tariff Name */}
            <Grid item xs={12} md={2} display='flex' alignItems='center'>
                <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                    {provider?.img ? (
                        <img
                            src={provider.img}
                            alt={`${plan['Supplier']} logo`}
                            style={{ width: '90px', maxHeight: '50px' }}
                        />
                    ) : (
                        <p>No Logo Available</p>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} md={10} display='flex' alignItems='center'>
                <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                    <p className='plan-title' style={{ margin: 0, padding: 0 }}>{plan['Tariff Name']}</p>
                </Box>
            </Grid>
            
            <Grid item xs={12}>
                <Divider className='plan-summary-divider'></Divider>
            </Grid>

            {/* Payment Type, Contract, and Meter Type */}
            <Grid item xs={6} md={4}>
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Payment Method</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Payment Type']}</p>
                </Box>
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Online Billing</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Online Billing'].split('/').indexOf('Y') > -1 ? 'Yes' : 'No'}</p>
                </Box>
            </Grid>
            <Grid item xs={6} md={4}>
                {props.form !== 'Gas' &&
                    <Box style={{marginBottom: '0.5rem'}}>
                        <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Rate</p>
                        <p style={{ margin: 0, padding: 0 }}>{plan['Rate Type']}</p>
                    </Box>
                }
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Contract</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Contract']}</p>
                </Box>
                {/*
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Meter Type</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Meter Type']}</p>
                </Box>
                */}
            </Grid>
            <Grid item xs={6} md={4}>
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Welcome Bonus</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Cash Bonus'] + (props.includeCashback === 'Yes' ? '(Included)' : '(Not Included)')}</p>
                </Box>
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>Exit Fee</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Termination Fee'] || '€0.00'}</p>
                </Box>
                {/*
                <Box style={{marginBottom: '0.5rem'}}>
                    <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>100% Green Energy</p>
                    <p style={{ margin: 0, padding: 0 }}>{plan['Fuel Mix'] === 'Renewables: 100%' ? 'Yes' : 'No'}</p>
                </Box>
                */}
            </Grid>

            {(plan['Tariff Type'] === 'Smart' || plan['Fuel Mix'] === 'Renewables: 100%' || plan['Extra']) && 
                <Grid item xs={12} display='flex' justifyContent='flex-start'>
                    {plan['Tariff Type'] === 'Smart' &&
                        <Box style={{marginBottom: '0rem', marginRight: '5px'}} className='plan-badge'>
                            <p style={{ margin: 0, padding: 0 }}>Smart Meter Required</p>
                        </Box>
                    }
                    {plan['Fuel Mix'] === 'Renewables: 100%' &&
                        <Box style={{marginBottom: '0rem', marginRight: '5px'}} className='plan-badge'>
                            <p style={{ margin: 0, padding: 0 }}>100% Green Energy</p>
                        </Box>
                    }
                    {plan['Extra'] &&
                        <Box style={{marginBottom: '0rem', marginRight: '5px'}} className='plan-badge'>
                            <p style={{ margin: 0, padding: 0 }}>{plan['Extra']}</p>
                        </Box>
                    }
                </Grid>
            }

            <Grid item xs={12}>
                <Divider className='plan-summary-divider'></Divider>
            </Grid>

            {/* Estimated Annual Bill and Savings */}
            <Grid item xs={12} md={6} display='flex' alignItems='center'>
                <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                    <p style={{ margin: 0, padding: 0, textAlign: 'center' }}>Estimated annual bill is €{estimatedBill} (Inc. VAT at 9.0%)</p>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                    <Button color={site} variant='contained' size='large' onClick={() => console.log('Select plan')}>{plan['savings'] > 0 ? 'Savings of €' + formatNumber(plan['savings']) : 'Switch'}</Button>
                </Box>
            </Grid>
        </Grid>
    )
}

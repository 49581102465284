import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import EESolarForm from '../../components/Forms/EESolarForm'
import EESolarCostWidget from '../../components/Forms/EESolarCostWidget'
import EEComparisonForm from '../../components/Forms/EEComparisonForm'
import SolarFinderForm from '../../components/Forms/SolarFinderForm'
import SolarFinderQuotesForm from '../../components/Forms/SolarFinderQuotesForm'
import IyiForm from '../../components/Forms/IyiForm'
import IyiQuotesForm from '../../components/Forms/IyiQuotesForm'
import LifeCompareForm from '../../components/Forms/LifeCompareForm'
import LifeCompareQuotesForm from '../../components/Forms/LifeCompareQuotesForm'
import NphTransferForm from '../../components/Forms/NphTransferForm'
import NphReviewForm from '../../components/Forms/NphReviewForm'
import NphPropertyForm from '../../components/Forms/NphPropertyForm'
import NphGeneralForm from '../../components/Forms/NphGeneralForm'

const Main = () => {
    return (
        <Switch>
            
            <Route exact path='/' component={EESolarForm} />
            <Route exact path='/solarcostwidget' component={EESolarCostWidget} />
            <Route exact path='/energycomparison' component={EEComparisonForm} />
            <Route exact path='/solarfinder' component={SolarFinderForm} />
            <Route exact path='/solarfinderquotes' component={SolarFinderQuotesForm} />
            <Route exact path='/iyi' component={IyiForm} />
            <Route exact path='/iyiquotes' component={IyiQuotesForm} />
            <Route exact path='/lifecompare' component={LifeCompareForm} />
            <Route exact path='/lifecomparequotes' component={LifeCompareQuotesForm} />
            <Route exact path='/nphtransfer' component={NphTransferForm} />
            <Route exact path='/nphreview' component={NphReviewForm} />
            <Route exact path='/nphproperty' component={NphPropertyForm} />
            <Route exact path='/nphgeneral' component={NphGeneralForm} />
            <Redirect from='*' to='/' />
           
        </Switch>
    )
}

export default Main
import moment from 'moment'

export function getSite(url) {
    const siteMap = {
        'https://calculator.energyefficiency.ie/energycomparison': 'energyefficiency',
        'https://calculator.energyswitch.ie/energycomparison': 'energyswitch',
    }

    return siteMap[url] || 'energyefficiency'
}

function getStandardPlan(plan, allPlans) {
    const standardPlan = allPlans.find(p => {
        // Find plan that matches standard plan ID
        const planIdMatches = p['ID']?.trim().toLowerCase() === plan['Standard Plan ID']?.trim().toLowerCase()
        return planIdMatches
    })

    if (!standardPlan) {
        console.error('Standard plan not found')
        return 0
    }

    return standardPlan
}

export function getStandardPlanForCustomDiscount(supplier, region, tariffType, allPlans) {
    const standardPlan = allPlans.find(p =>
        p['Supplier'].trim().toLowerCase() === supplier.trim().toLowerCase() &&
        p['Region'].trim().toLowerCase() === region.trim().toLowerCase() &&
        p['Tariff Type'].trim().toLowerCase() === tariffType.trim().toLowerCase() &&
        parseFloat(p['Discount'].replace('%', '')) === 0
    )

    if (!standardPlan) {
        console.error('Standard plan not found')
        return 0
    }

    return standardPlan
}

function getRates(plan, type) {
    if (type === 'electricity') {
        return {
            discount: parseFloat(plan['Discount'].replace('%', '')) / 100 || 0,
            dayRate: parseFloat(plan['Day Rate (Exc VAT)']) / 100 || 0,
            nightRate: parseFloat(plan['Night Rate (Exc VAT)']) / 100 || 0,
            peakRate: parseFloat(plan['Peak Rate (Exc VAT)']) / 100 || 0,
            evRate: parseFloat(plan['EV Rate (Exc VAT)']) / 100 || 0,
            psoLevy: parseFloat(plan['PSO Levy (Exc VAT)'].replace('€', '')) || 0,
            standingCharge: parseFloat(plan['Standing Charge (Exc VAT)'].replace('€', '')) || 0,
            prepaymentServiceCharge: parseFloat(plan['Prepayment Service Charge (Exc VAT)'].replace('€', '')) || 0
        }
    }
    else if (type === 'gas') {
        return {
            discount: parseFloat(plan['Discount'].replace('%', '')) / 100 || 0,
            gasRate: parseFloat(plan['Gas Rate (Exc VAT)']) / 100 || 0,
            carbonTax: parseFloat(plan['Carbon Tax (Exc VAT)']) / 100 || 0,
            standingCharge: parseFloat(plan['Standing Charge (Exc VAT)'].replace('€', '')) || 0,
            prepaymentServiceCharge: parseFloat(plan['Prepayment Service Charge (Exc VAT)'].replace('€', '')) || 0
        }
    }
    else if (type === 'dual') {
        return {
            electricityDiscount: parseFloat(plan['Electricity Discount'].replace('%', '')) / 100 || 0,
            gasDiscount: parseFloat(plan['Gas Discount'].replace('%', '')) / 100 || 0,
            dayRate: parseFloat(plan['Day Rate (Exc VAT)']) / 100 || 0,
            nightRate: parseFloat(plan['Night Rate (Exc VAT)']) / 100 || 0,
            peakRate: parseFloat(plan['Peak Rate (Exc VAT)']) / 100 || 0,
            evRate: parseFloat(plan['EV Rate (Exc VAT)']) / 100 || 0,
            gasRate: parseFloat(plan['Gas Rate (Exc VAT)']) / 100 || 0,
            carbonTax: parseFloat(plan['Carbon Tax (Exc VAT)']) / 100 || 0,
            psoLevy: parseFloat(plan['PSO Levy (Exc VAT)'].replace('€', '')) || 0,
            electricityStandingCharge: parseFloat(plan['Electricity Standing Charge (Exc VAT)'].replace('€', '')) || 0,
            gasStandingCharge: parseFloat(plan['Gas Standing Charge (Exc VAT)'].replace('€', '')) || 0,
            gasPrepaymentServiceCharge: parseFloat(plan['Gas Prepayment Service Charge'].replace('€', '')) || 0,
            electricityPrepaymentServiceCharge: parseFloat(plan['Electricity Prepayment Service Charge'].replace('€', '')) || 0,
        }
    }
    else {
        return null
    }
}

// Function to calculate the cost based on plan details
// TODO; Exceptions here should be stored in a file or DB where they can be managed better
function calculateUsageCostElectricity(plan, rates, nightsaverPercentage, electricityUsage, includeCharges) {
    console.log('plan: ', plan)
    console.log('rates: ', rates)
    console.log('nightsaverPercentage: ', nightsaverPercentage)
    console.log('electricityUsage: ', electricityUsage)
    console.log('includeCharges: ', includeCharges)
    // get correct rate - dual is named differently
    const discount = rates.discount !== undefined 
        ? rates.discount 
        : rates.electricityDiscount
    const standingCharge = rates.standingCharge !== undefined 
        ? rates.standingCharge 
        : rates.electricityStandingCharge
    const prepaymentServiceCharge = rates.prepaymentServiceCharge !== undefined 
        ? rates.prepaymentServiceCharge 
        : rates.electricityPrepaymentServiceCharge
    
    console.log('discount: ', discount)
    console.log('standingCharge: ', standingCharge)
    console.log('prepaymentServiceCharge: ', prepaymentServiceCharge)

    let usageCost = 0

    if (plan['Tariff Type'] === '24hr') {
        const discountedRate = rates.dayRate * (1 - discount)
        usageCost = electricityUsage * discountedRate
        console.log('discounted rate: ', discountedRate)
    } 
    else if (plan['Tariff Type'] === 'Nightsaver') {
        const discountedDayRate = rates.dayRate * (1 - discount)
        const discountedNightRate = rates.nightRate * (1 - discount)
        const dayUsage = electricityUsage * (1 - nightsaverPercentage)
        const nightUsage = electricityUsage * nightsaverPercentage
        const dayCost = dayUsage * discountedDayRate
        const nightCost = nightUsage * discountedNightRate
        usageCost = dayCost + nightCost
        // console.log('day rate: ', discountedDayRate)
        // console.log('night rate: ', discountedNightRate)
        // console.log('day usage: ', dayUsage)
        // console.log('night usage: ', nightUsage)
        // console.log('day cost: ', dayCost)
        // console.log('night cost: ', nightCost)
        // console.log('usage cost: ', usageCost)
    } 
    else if (plan['Tariff Type'] === 'Smart') {
        const discountedDayRate = rates.dayRate * (1 - discount)
        const discountedNightRate = rates.nightRate * (1 - discount)
        const discountedPeakRate = rates.peakRate * (1 - discount)
        const discountedEvRate = rates.evRate * (1 - discount)

        if (rates.dayRate && !rates.nightRate && !rates.peakRate && !rates.evRate) {
            usageCost = electricityUsage * discountedDayRate
        } 
        else if (rates.dayRate && rates.nightRate && !rates.peakRate && !rates.evRate) {
            const dayCost = electricityUsage * 0.62 * discountedDayRate
            const nightCost = electricityUsage * 0.38 * discountedNightRate
            usageCost = dayCost + nightCost
        } 
        else if (rates.dayRate && rates.nightRate && rates.peakRate && !rates.evRate) {
            // Differenciate between urban/rural for Day/Night/Peak rates
            const percentages = {
                'urban': {
                    day: 0.54,
                    night: 0.37,
                    peak: 0.09
                },
                'rural': {
                    day: 0.55,
                    night: 0.34,
                    peak: 0.11
                }
            }
            const dayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * discountedDayRate
            console.log('day cost: ', dayCost)
            const peakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * discountedPeakRate
            console.log('peak cost: ', peakCost)
            const nightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].night * discountedNightRate
            console.log('night cost: ', nightCost)
            usageCost = dayCost + peakCost + nightCost
        } 
        else if (rates.dayRate && rates.nightRate && rates.peakRate && rates.evRate) {
            const percentages = {
                'Electric Ireland': {
                    'urban': {
                        day: 0.535,
                        night: 0.29,
                        peak: 0.095,
                        ev: 0.08
                    },
                    'rural': {
                        day: 0.55,
                        night: 0.27,
                        peak: 0.11,
                        ev: 0.07
                    }
                },
                'SSE Airtricity': {
                    'urban': {
                        day: 0.53,
                        night: 0.25,
                        peak: 0.09,
                        ev: 0.13
                    },
                    'rural': {
                        day: 0.545,
                        night: 0.245,
                        peak: 0.11,
                        ev: 0.10
                    }
                },
                'Bord Gais': {
                    'urban': {
                        day: 0.55,
                        night: 0.24,
                        peak: 0.08,
                        ev: 0.13
                    },
                    'rural': {
                        day: 0.565,
                        night: 0.24,
                        peak: 0.095,
                        ev: 0.10
                    }
                },
                'Energia': {
                    'urban': {
                        day: 0.84,
                        night: 0,
                        peak: 0,
                        ev: 0.16
                    },
                    'rural': {
                        day: 0.87,
                        night: 0,
                        peak: 0,
                        ev: 0.13
                    }
                },
                'Pinergy': {
                    'urban': {
                        day: 0.87,
                        night: 0,
                        peak: 0,
                        ev: 0.13
                    },
                    'rural': {
                        day: 0.90,
                        night: 0,
                        peak: 0,
                        ev: 0.10
                    }
                },
                'fallback': {
                    day: 0.53,
                    night: 0.24,
                    peak: 0.09,
                    ev: 0.14
                }
            }
        
            const region = plan['Region']?.trim().toLowerCase()
            const supplier = plan['Supplier']
            const supplierPercentages = percentages[supplier]?.[region] || percentages['fallback']
            console.log('Using percentages:', supplierPercentages)
        
            const dayCost = electricityUsage * (supplierPercentages.day) * discountedDayRate
            console.log('day cost: ', dayCost)
        
            const peakCost = electricityUsage * (supplierPercentages.peak) * discountedPeakRate
            console.log('peak cost: ', peakCost)
        
            const nightCost = electricityUsage * (supplierPercentages.night) * discountedNightRate
            console.log('night cost: ', nightCost)
        
            const evCost = electricityUsage * (supplierPercentages.ev) * discountedEvRate
            console.log('ev cost: ', evCost)
        
            usageCost = dayCost + peakCost + nightCost + evCost
        }        
    }

    // Exceptions 

    // Pinergy 'Family Time'
    // dayRate used as normal and peak for 5 hours
    if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === "Pinergy Lifestyle 'Family Time'") {
        console.log('EXCEPTION - Pinergy family time')
        const percentages = {
            'urban': {
                day: 0.78,
                peak: 0.22
            },
            'rural': {
                day: 0.80,
                peak: 0.20
            }
        }
        const normalRateCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * rates.dayRate
        console.log('normal rate cost (19 hours): ', normalRateCost)
        const specialRateCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * rates.peakRate
        console.log('special rate cost (5 hours): ', specialRateCost)
        usageCost = normalRateCost + specialRateCost
    }
    // Pinergy 'Working from Home'
    // peakRate used as normal and day for 8 hours - should be switched for consistency
    if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === "Pinergy Lifestyle 'Working From Home Time'") {
        console.log('EXCEPTION - Pinergy working from home time')
        const percentages = {
            'urban': {
                day: 0.77,
                peak: 0.23
            },
            'rural': {
                day: 0.77,
                peak: 0.23
            }
        }
        const normalRateCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * rates.dayRate
        console.log('normal rate cost (16 hours): ', normalRateCost)
        const specialRateCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * rates.peakRate
        console.log('special rate cost (8 hours): ', specialRateCost)
        usageCost = normalRateCost + specialRateCost
    }
    // Pinergy 'EV Drive Time'
    // if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === "Pinergy Lifestyle 'EV Drive Time'") {
    //     console.log('EXCEPTION - Pinergy EV drive time')
    //     const normalRateCost = electricityUsage * (21/24) * rates.dayRate
    //     console.log('normal rate cost (21 hours): ', normalRateCost)
    //     const specialRateCost = electricityUsage * (3/24) * rates.evRate
    //     console.log('special rate cost (3 hours): ', specialRateCost)
    //     usageCost = normalRateCost + specialRateCost
    // }
    // Pinergy 'Smart 7 to 10 24hr'
    if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === 'Pinergy Smart 7 to 10') {
        console.log('EXCEPTION - Pinergy Smart 7 to 10 24hr & 7 to 10 nightsaver')
        const normalRateCost = electricityUsage * 0.88 * rates.dayRate
        console.log('normal rate cost: ', normalRateCost)
        const specialRateCost = electricityUsage * 0.12 * rates.peakRate
        console.log('special rate cost: ', specialRateCost)
        usageCost = normalRateCost + specialRateCost
    }
    // SSE Airtricity weekend plans
    // May be better to list plan IDs or names here insead of using includes 
    if (plan['Supplier'] === 'SSE Airtricity' && plan['Tariff Name'].includes('Smart Weekends')) {
        console.log('EXCEPTION - SSE Smart Weekends')
        const percentages = {
            'urban': {
                day: 0.39,
                night: 0.28,
                peak: 0.06,
                weekendDay: 0.15,
                weekendNight: 0.09,
                weekendPeak: 0.03
            },
            'rural': {
                day: 0.40,
                night: 0.26,
                peak: 0.08,
                weekendDay: 0.15,
                weekendNight: 0.09,
                weekendPeak: 0.03
            }
        }
        const discountedDayRate = rates.dayRate * (1 - discount)
        const discountedNightRate = rates.nightRate * (1 - discount)
        const discountedPeakRate = rates.peakRate * (1 - discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const weekDayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * discountedDayRate
        const weekPeakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * discountedPeakRate
        const weekNightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].night * discountedNightRate
        const weekendDayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].weekendDay * (discountedDayRate / 2)
        const weekendPeakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].weekendPeak * (discountedPeakRate / 2)
        const weekendNightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].weekendNight * (discountedNightRate / 2)
        usageCost = weekDayCost + weekPeakCost + weekNightCost + weekendDayCost + weekendPeakCost + weekendNightCost
    } 
    // Bord Gais weekend plans
    if (plan['Supplier'] === 'Bord Gais' && (plan['Tariff Name'].includes('Smart Weekend Electricity') || plan['Tariff Name'].includes('Weekend Smart Electricity'))) {
        console.log('EXCEPTION - Bord Gais Weekend Electricity')
        const percentages = {
            'urban': {
                day: 0.38,
                night: 0.24,
                peak: 0.06,
                weekend: 0.32
            },
            'rural': {
                day: 0.39,
                night: 0.21,
                peak: 0.08,
                weekend: 0.32
            }
        }
        const discountedDayRate = rates.dayRate * (1 - discount)
        const discountedNightRate = rates.nightRate * (1 - discount)
        const discountedPeakRate = rates.peakRate * (1 - discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const dayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * discountedDayRate
        const peakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * discountedPeakRate
        const nightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].night * discountedNightRate
        const weekendCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].weekend * discountedNightRate
        usageCost = dayCost + peakCost + nightCost + weekendCost
    }
    // Bord Gais single day free rates
    if (plan['Supplier'] === 'Bord Gais' && (plan['Tariff Name'].includes('Free Time Saturday') || plan['Tariff Name'].includes('Free Time Sunday') || plan['Tariff Name'].includes('Green Free Saturday') || plan['Tariff Name'].includes('Green Free Sunday'))) {
        console.log('EXCEPTION - Bord Gais Single Day Free Electricity')
        const percentages = {
            'urban': {
                day: 0.49,
                night: 0.38,
                peak: 0.08,
                free: 0.05
            },
            'rural': {
                day: 0.50,
                night: 0.34,
                peak: 0.10,
                free: 0.06
            }
        }
        const discountedDayRate = rates.dayRate * (1 - discount)
        const discountedNightRate = rates.nightRate * (1 - discount)
        const discountedPeakRate = rates.peakRate * (1 - discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const dayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * discountedDayRate
        const peakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * discountedPeakRate
        const nightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].night * discountedNightRate
        const zeroCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].free * 0
        usageCost = dayCost + peakCost + nightCost + zeroCost
    }
    // Electric Ireland Weekender
    if (plan['Supplier'] === 'Electric Ireland' && plan['Tariff Name'] === 'Home Electric+ Weekender') {
        console.log('EXCEPTION - Electric Ireland Weekender')
        const percentages = {
            'urban': {
                day: 0.54,
                night: 0.37,
                peak: 0.09
            },
            'rural': {
                day: 0.56,
                night: 0.34,
                peak: 0.10
            }
        }
        const discountedDayRate = rates.dayRate * (1 - discount)
        const discountedNightRate = rates.nightRate * (1 - discount)
        const discountedPeakRate = rates.peakRate * (1 - discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const dayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * discountedDayRate
        const peakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * discountedPeakRate
        const nightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].night * discountedNightRate
        //const zeroCost = electricityUsage * 0.0845 * 0
        usageCost = dayCost + peakCost + nightCost
    }

    // Add PSO Levy and Standing Charge to the usage cost if set to true
    if (includeCharges) {
        usageCost += (rates.psoLevy + standingCharge + prepaymentServiceCharge)
    }
    return usageCost
}

// Function to calculate the cost based on plan details
function calculateUsageCostGas(rates, gasUsage, includeCharges) {
    // get correct rate - dual is named differently
    const discount = rates.discount !== undefined 
        ? rates.discount 
        : rates.gasDiscount
    const standingCharge = rates.standingCharge !== undefined 
        ? rates.standingCharge 
        : rates.gasStandingCharge
    const prepaymentServiceCharge = rates.prepaymentServiceCharge !== undefined 
        ? rates.prepaymentServiceCharge 
        : rates.gasPrepaymentServiceCharge
    
    let usageCost = 0

    const discountedRate = rates.gasRate * (1 - discount)
    usageCost = gasUsage * discountedRate

    const carbonTax = gasUsage * rates.carbonTax
    usageCost += carbonTax

    if (includeCharges) {
        usageCost += (standingCharge + prepaymentServiceCharge)
    }
    return usageCost
}

function calculateUsageCostDual(plan, rates, nightsaverPercentage, electricityUsage, gasUsage, includeCharges) {
    const electricityUsageCost = calculateUsageCostElectricity(plan, rates, nightsaverPercentage, electricityUsage, false)
    console.log('electricity usage for dual: ', electricityUsageCost)

    const gasUsageCost = calculateUsageCostGas(rates, gasUsage, false)
    console.log('gas usage for dual: ', gasUsageCost)

    let usageCost = electricityUsageCost + gasUsageCost
    console.log('combined usage before charges for dual: ', usageCost)

    // Add PSO Levy and Standing Charge to the usage cost if set to true
    if (includeCharges) {
        usageCost += (rates.psoLevy + rates.electricityStandingCharge + rates.gasStandingCharge + rates.electricityPrepaymentServiceCharge + rates.gasPrepaymentServiceCharge)
    }
    return usageCost
}

export function calculateCurrentBillElectricity(plan, nightsaverPercentage, electricityTotal, tariffStart, allPlans) {
    // all calculations done excluding VAT
    console.log('nightsaver %: ', nightsaverPercentage)
    console.log('electricity total: ', electricityTotal)
    console.log('tariff start: ', tariffStart)

    // Calculate days on current plan
    const startDate = !tariffStart || tariffStart === 'Over 12 months ago'
    ? moment().subtract(365, 'days') // Default to exactly 12 months ago
    : moment(tariffStart, 'D MMMM YYYY') // Parse the start date

    const currentDate = moment()
    // Calculate the total number of days on the current plan
    let daysOnCurrentPlan = currentDate.diff(startDate, 'days')

    // Cap the days to a maximum of 365
    if (daysOnCurrentPlan > 365) {
        daysOnCurrentPlan = 365
    } 
    else if (daysOnCurrentPlan < 0) {
        daysOnCurrentPlan = 0 // Avoid negative days
    }
    // Calculate the days on the standard plan (remaining days in the year)
    const daysOnStandardPlan = 365 - daysOnCurrentPlan

    console.log('Days on current plan:', daysOnCurrentPlan)
    console.log('Days on standard plan:', daysOnStandardPlan)

    const currentPlanUsage = (daysOnCurrentPlan / 365) * electricityTotal
    const standardPlanUsage = (daysOnStandardPlan / 365) * electricityTotal
    console.log('current plan usage: ', currentPlanUsage)
    console.log('standard plan usage: ', standardPlanUsage)

    // Find the corresponding 'Standard plan' version of the current plan
    const standardPlan = getStandardPlan(plan, allPlans)
    console.log('standard electricity plan: ', standardPlan)

    // rates - what is used is determined by tariffType
    const currentPlanRates = getRates(plan, 'electricity')
    console.log('current plan rates: ', currentPlanRates)
    const standardPlanRates = getRates(standardPlan, 'electricity')
    console.log('standard plan rates: ', currentPlanRates)

    let totalBill = 0

    // Calculate bill for current plan usage - include charges
    totalBill += calculateUsageCostElectricity(plan, currentPlanRates, nightsaverPercentage, currentPlanUsage, true)
    console.log('total bill current: ', totalBill)

    // Calculate bill for standard plan usage - exclude charges as included above
    totalBill += calculateUsageCostElectricity(standardPlan, standardPlanRates, nightsaverPercentage, standardPlanUsage, false)
    console.log('total bill standard: ', totalBill)

    return totalBill
}

export function calculateCurrentBillGas(plan, gasTotal, tariffStart, allPlans) {
    // all calculations done excluding VAT
    console.log('gas total: ', gasTotal)
    console.log('tariff start: ', tariffStart)

    // Calculate days on current plan
    const startDate = !tariffStart || tariffStart === 'Over 12 months ago'
    ? moment().subtract(365, 'days') // Default to exactly 12 months ago
    : moment(tariffStart, 'D MMMM YYYY') // Parse the start date

    const currentDate = moment()

    // Calculate the total number of days on the current plan
    let daysOnCurrentPlan = currentDate.diff(startDate, 'days')

    // Cap the days to a maximum of 365
    if (daysOnCurrentPlan > 365) {
        daysOnCurrentPlan = 365
    } 
    else if (daysOnCurrentPlan < 0) {
        daysOnCurrentPlan = 0 // Avoid negative days
    }

    // Calculate the days on the standard plan (remaining days in the year)
    const daysOnStandardPlan = 365 - daysOnCurrentPlan

    console.log('Days on current plan:', daysOnCurrentPlan)
    console.log('Days on standard plan:', daysOnStandardPlan)

    const currentPlanUsage = (daysOnCurrentPlan / 365) * gasTotal
    const standardPlanUsage = (daysOnStandardPlan / 365) * gasTotal
    console.log('current plan usage: ', currentPlanUsage)
    console.log('standard plan usage: ', standardPlanUsage)

    // Find the corresponding 'Standard plan' version of the current plan
    const standardPlan = getStandardPlan(plan, allPlans)
    console.log('standard gas plan: ', standardPlan)

    // rates - what is used is determined by tariffType
    const currentPlanRates = getRates(plan, 'gas')
    console.log('current plan rates: ', currentPlanRates)
    const standardPlanRates = getRates(standardPlan, 'gas')
    console.log('standard plan rates: ', currentPlanRates)

    let totalBill = 0

    // Calculate bill for current plan usage - include charges
    totalBill += calculateUsageCostGas(currentPlanRates, currentPlanUsage, true)
    console.log('total bill current: ', totalBill)

    // Calculate bill for standard plan usage - exclude charges as included above
    totalBill += calculateUsageCostGas(standardPlanRates, standardPlanUsage, false)
    console.log('total bill standard: ', totalBill)

    return totalBill
}

export function calculateCurrentBillDual(plan, nightsaverPercentage, electricityTotal, gasTotal, tariffStart, allPlans) {
    // all calculations done excluding VAT
    console.log('nightsaver %: ', nightsaverPercentage)
    console.log('electricity total: ', electricityTotal)
    console.log('gas total: ', gasTotal)
    console.log('tariff start: ', tariffStart)

    // Calculate days on current plan
    const startDate = !tariffStart || tariffStart === 'Over 12 months ago'
    ? moment().subtract(365, 'days') // Default to exactly 12 months ago
    : moment(tariffStart, 'D MMMM YYYY') // Parse the start date

    const currentDate = moment()
    // Calculate the total number of days on the current plan
    let daysOnCurrentPlan = currentDate.diff(startDate, 'days')

    // Cap the days to a maximum of 365
    if (daysOnCurrentPlan > 365) {
        daysOnCurrentPlan = 365
    } 
    else if (daysOnCurrentPlan < 0) {
        daysOnCurrentPlan = 0 // Avoid negative days
    }
    // Calculate the days on the standard plan (remaining days in the year)
    const daysOnStandardPlan = 365 - daysOnCurrentPlan

    console.log('Days on current plan:', daysOnCurrentPlan)
    console.log('Days on standard plan:', daysOnStandardPlan)

    // Electricity usage
    const currentPlanUsageElectricity = (daysOnCurrentPlan / 365) * electricityTotal
    const standardPlanUsageElectricity = (daysOnStandardPlan / 365) * electricityTotal
    console.log('current plan usage electricity: ', currentPlanUsageElectricity)
    console.log('standard plan usage electricity: ', standardPlanUsageElectricity)

    // Gas usage
    const currentPlanUsageGas = (daysOnCurrentPlan / 365) * gasTotal
    const standardPlanUsageGas = (daysOnStandardPlan / 365) * gasTotal
    console.log('current plan usage gas: ', currentPlanUsageGas)
    console.log('standard plan usage gas: ', standardPlanUsageGas)

    // Find the corresponding 'Standard plan' version of the current plan
    const standardPlan = getStandardPlan(plan, allPlans)
    console.log('standard electricity plan: ', standardPlan)

    // rates - what is used is determined by tariffType
    const currentPlanRates = getRates(plan, 'dual')
    console.log('current plan rates: ', currentPlanRates)
    const standardPlanRates = getRates(standardPlan, 'dual')
    console.log('standard plan rates: ', currentPlanRates)

    let totalBill = 0

    // Calculate bill for current plan usage - include charges
    totalBill += calculateUsageCostDual(plan, currentPlanRates, nightsaverPercentage, currentPlanUsageElectricity, currentPlanUsageGas, true)
    console.log('total bill current: ', totalBill)

    // Calculate bill for standard plan usage - exclude charges as included above
    totalBill += calculateUsageCostDual(standardPlan, standardPlanRates, nightsaverPercentage, standardPlanUsageElectricity, standardPlanUsageGas, false)
    console.log('total bill current + standard: ', totalBill)

    return totalBill
}

export function calculateAllBillsElectricity(allPlans, nightsaverPercentage, electricityTotal, includeSmartPlans, includeCashback) {
    const updatedPlans = allPlans.map(plan => {
        const rates = getRates(plan, 'electricity')

        // Skip smart plans if includeSmartPlans is false
        if (includeSmartPlans === 'No' && plan['Tariff Type'] === 'Smart') {
            return null
        }

        // Calculate usage cost
        const totalAnnualBill = calculateUsageCostElectricity(plan, rates, nightsaverPercentage, electricityTotal, true)

        // Include cashback if applicable
        if (includeCashback === 'Yes' && plan['Cash Bonus'] && plan['Cash Bonus'] !== '€0') {
            const cashback = parseFloat(plan['Cash Bonus'].replace('€', '')) || 0
            return { 
                ...plan, 
                estimatedAnnualBill: totalAnnualBill - cashback
            }
        }

        return { 
            ...plan, 
            estimatedAnnualBill: totalAnnualBill
        }
    }).filter(plan => plan !== null) // Remove null values for plans not included

    // Sort by estimated annual bill (cheapest first)
    return updatedPlans.sort((a, b) => a.estimatedAnnualBill - b.estimatedAnnualBill)
}

export function calculateAllBillsGas(allPlans, gasTotal, includeCashback) {
    const updatedPlans = allPlans.map(plan => {
        const rates = getRates(plan, 'gas')

        // Calculate usage cost
        const totalAnnualBill = calculateUsageCostGas(rates, gasTotal, true)

        // Include cashback if applicable
        if (includeCashback === 'Yes' && plan['Cash Bonus'] && plan['Cash Bonus'] !== '€0') {
            const cashback = parseFloat(plan['Cash Bonus'].replace('€', '')) || 0
            return { 
                ...plan, 
                estimatedAnnualBill: totalAnnualBill - cashback
            }
        }

        return { 
            ...plan, 
            estimatedAnnualBill: totalAnnualBill
        }
    }).filter(plan => plan !== null) // Remove null values for plans not included

    // Sort by estimated annual bill (cheapest first)
    return updatedPlans.sort((a, b) => a.estimatedAnnualBill - b.estimatedAnnualBill)
}

export function calculateAllBillsDual(allPlans, nightsaverPercentage, electricityTotal, gasTotal, includeSmartPlans, includeCashback) {
    const updatedPlans = allPlans.map(plan => {
        const rates = getRates(plan, 'dual')

        // Skip smart plans if includeSmartPlans is false
        if (includeSmartPlans === 'No' && plan['Tariff Type'] === 'Smart') {
            return null
        }

        // Calculate usage cost
        const totalAnnualBill = calculateUsageCostDual(plan, rates, nightsaverPercentage, electricityTotal, gasTotal, true)

        // Include cashback if applicable
        if (includeCashback === 'Yes' && plan['Cash Bonus'] && plan['Cash Bonus'] !== '€0') {
            const cashback = parseFloat(plan['Cash Bonus'].replace('€', '')) || 0
            return { 
                ...plan, 
                estimatedAnnualBill: totalAnnualBill - cashback
            }
        }

        return { 
            ...plan, 
            estimatedAnnualBill: totalAnnualBill
        }
    }).filter(plan => plan !== null) // Remove null values for plans not included

    // Sort by estimated annual bill (cheapest first)
    return updatedPlans.sort((a, b) => a.estimatedAnnualBill - b.estimatedAnnualBill)
}

export function getTariffStartOptions(earliestDate, endDate) {
    let options = []
  
    // Handle 'Earliest Date' special cases
    if (earliestDate === 'N/A') {
        return null // Hide the question completely
    }

    if (earliestDate === 'Over 12 Months Ago') {
        options.push({label: 'Over 12 months ago', value: 'Over 12 months ago'})
        // Add the last 12 months to the options
        let current = moment().subtract(11, 'months').startOf('month') // Start 11 months ago
        for (let i = 0; i < 12; i++) {
            options.push({
                label: current.format('MMMM YYYY'), 
                value: current.format('D MMMM YYYY')
            })
            current.add(1, 'months')
        }
        return options
    }

    // Parse earliest date if it's a valid month-year format
    let parsedEarliestDate = moment(earliestDate, 'D MMMM YYYY', true)
    if (!parsedEarliestDate.isValid()) {
        console.warn('Invalid Earliest Date:', earliestDate)
        return null // Hide the question completely if parsing fails
    }

    // Parse end date if available
    let parsedEndDate
    if (endDate === 'Now' || endDate === 'N/A') {
        parsedEndDate = moment() // Use current date if 'Now' or 'N/A'
    } 
    else {
        parsedEndDate = moment(endDate, 'D MMMM YYYY', true)
        if (!parsedEndDate.isValid()) {
            console.warn('Invalid End Date:', endDate)
            parsedEndDate = moment() // Default to current date if parsing fails
        }
    }
    console.log('Parsed Earliest Date:', parsedEarliestDate.format('D MMMM YYYY'))
    console.log('Parsed End Date:', parsedEndDate.format('D MMMM YYYY'))

    // Ensure the date range is valid (earliestDate should not be after endDate)
    if (parsedEarliestDate.isAfter(parsedEndDate)) {
        console.warn('Earliest date is after end date. Returning empty options.')
        return []
    }

    // Determine the date range for options
    let current = moment(parsedEarliestDate)
    while (current.isSameOrBefore(parsedEndDate)) {
        options.push({
            label: current.format('MMMM YYYY'), 
            value: current.format('D MMMM YYYY')
        })
        // after first month default to start of month
        current.add(1, 'months').startOf('month')
    }

    console.log('Generated Options:', options)
    return options
}

export function validateFormData(form, formData, sameSupplier) {
    const errors = []
  
    if (form === 'Electricity') {
        const { planId, supplier, region, tariffType } = formData.electricity
        if (!planId) errors.push('Tariff Name')
        if (!supplier) errors.push('Electricity Supplier')
        if (!region) errors.push('Electricity Region')
        if (!tariffType) errors.push('Electricity Tariff Type')
    }
  
    if (form === 'Gas') {
        const { planId, supplier, paymentMethod } = formData.gas
        if (!planId) errors.push('Tariff Name')
        if (!supplier) errors.push('Gas Supplier')
        if (!paymentMethod) errors.push('Gas Payment Method')
    }
  
    if (form === 'Gas & Electricity' && sameSupplier === 'Yes') {
        const { planId, supplier, region, tariffType } = formData.dual
        if (!planId) errors.push('Tariff Name')
        if (!supplier) errors.push('Dual Fuel Supplier')
        if (!region) errors.push('Dual Fuel Region')
        if (!tariffType) errors.push('Dual Fuel Tariff Type')
    }
  
    if (form === 'Gas & Electricity' && sameSupplier === 'No') {
        const { elecPlanId, elecSupplier, region, tariffType } = formData.electricity
        const { gasPlanId, gasSupplier, paymentMethod } = formData.gas
        
        if (!elecPlanId) errors.push('Electricity Tariff Name')
        if (!elecSupplier) errors.push('Electricity Supplier')
        if (!region) errors.push('Electricity Region')
        if (!tariffType) errors.push('Electricity Tariff Type')
        if (!gasPlanId) errors.push('Gas Tariff Name')
        if (!gasSupplier) errors.push('Gas Supplier')
        if (!paymentMethod) errors.push('Gas Payment Method')
    }
  
    return errors
}

export function getExitFee(plan, tariffStart) {
    let exitFee = 0
    const contractLength = parseInt(plan['Contract'], 10)
    console.log('contract length: ', contractLength)
    const contractStartDate = moment(tariffStart, 'MMMM YYYY')
    console.log('contract start: ', contractStartDate)
    const contractEndDate = contractStartDate.clone().add(contractLength, 'months')
    console.log('contract end: ', contractEndDate)
    const currentDate = moment()
    // Check if the contract is still active
    if (currentDate.isBefore(contractEndDate)) {
        exitFee = parseFloat(plan['Termination Fee'].replace('€', '')) || 0
        //console.log('contract active - exit fee: ', exitFee)
    }
    return exitFee
}

export function filterPlansForResultsElectricity(region, tariffType, selectedPlan, allPlans) {
    const filteredPlans = allPlans.filter(plan => {
        const customerEligibility = plan['Customer Eligibility']?.toLowerCase()
        const isSamePlan = plan['ID'] === selectedPlan['ID']
        const isSameSupplier = plan['Supplier'] === selectedPlan['Supplier']
        const isSameRegion = plan['Region'] === region
        const isActive = plan['Show in Results'] === 'Y'

        // Exclude current plan
        if (isSamePlan) {
            return false
        }

        // Exclude plans that are not active
        if (!isActive) {
            return false
        }
    
        // Exclude plans that do not match the selected region
        if (!isSameRegion) {
            return false
        }

        // Exclude plans that do not match the selected tariff type
        if (tariffType === '24hr') {
            if (plan['Tariff Type'] !== '24hr' && plan['Tariff Type'] !== 'Smart') {
                return false
            }
        } 
        else if (tariffType === 'Nightsaver') {
            if (plan['Tariff Type'] !== 'Nightsaver' && plan['Tariff Type'] !== 'Smart') {
                return false
            }
        } 
        else if (tariffType === 'Smart') {
            if (plan['Tariff Type'] !== 'Smart') {
                return false
            }
        }
    
        // Logic for 'Customer Eligibility'
        if (customerEligibility?.includes('new') && customerEligibility?.includes('existing')) {
            // 'New, Existing' case: Always include
            return true
        } 
        else if (customerEligibility?.includes('new')) {
            // 'New' case: Include only if different supplier
            return !isSameSupplier
        } 
        else if (customerEligibility?.includes('existing')) {
            // 'Existing' case: Include only if same supplier
            return isSameSupplier
        }
    
        // Default: Include if no specific customer eligibility restrictions
        return true
    })

    return filteredPlans
}

export function filterPlansForResultsGas(selectedPlan, allPlans) {
    const filteredPlans = allPlans.filter(plan => {
        const customerEligibility = plan['Customer Eligibility']?.toLowerCase()
        const isSamePlan = plan['ID'] === selectedPlan['ID']
        const isSameSupplier = plan['Supplier'] === selectedPlan['Supplier']
        const isActive = plan['Show in Results'] === 'Y'

        // Exclude current plan
        if (isSamePlan) {
            return false
        }

        // Exclude plans that are not active
        if (!isActive) {
            return false
        }
    
        // Logic for 'Customer Eligibility'
        if (customerEligibility?.includes('new') && customerEligibility?.includes('existing')) {
            // 'New, Existing' case: Always include
            return true
        } 
        else if (customerEligibility?.includes('new')) {
            // 'New' case: Include only if different supplier
            return !isSameSupplier
        } 
        else if (customerEligibility?.includes('existing')) {
            // 'Existing' case: Include only if same supplier
            return isSameSupplier
        }
    
        // Default: Include if no specific customer eligibility restrictions
        return true
    })

    return filteredPlans
}
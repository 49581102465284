import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import OptionsQuestion from '../Comparison/OptionsQuestion'
import SliderQuestion from '../Comparison/SliderQuestion'
import * as api from '../../config/api/GuestAPI'

import '../../widget.css'

export default function ElectricityForm(props) {
    const [data, setData] = useState(null)
    const [panels, setPanels] = useState(10)
    const [battery, setBattery] = useState('No')
    const [grant, setGrant] = useState('2025')
    const [estimatedCost, setEstimatedCost] = useState('')
    const [annualSavings, setAnnualSavings] = useState('')
    const [lifetimeSavings, setLifetimeSavings] = useState('')
    const [paybackPeriod, setPaybackPeriod] = useState('')
    const [powerOutput, setPowerOutput] = useState('')
    const [panelMessage, setPanelMessage] = useState('')
    const [batteryMessage, setBatteryMessage] = useState('')
    const [grantMessage, setGrantMessage] = useState('')
    const [messages, setMessages] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    // Recalculate cost when any input changes
    useEffect(() => {
        if (data) {
            calculateCost()
        }
    }, [panels, battery, grant])

    async function getData() {
        try {
            const response = await api.getSolarCostWidgetData()
            console.log('response: ', response)
            setData(response)
            
            if (response) {
                calculateCost(response) // Pass the response to calculateCost
            }
        } catch(e) {
            console.log('Error getting cost data: ', e)
        }
    }

    function getPaybackPeriod(system) {
        if (grant === '2024') {
            return battery === 'Yes' ? system['Payback Period (NB & 2024)'] : system['Payback Period (B & 2024)']
        }
        if (grant === '2025') {
            return battery === 'Yes' ? system['Payback Period (NB & 2025)'] : system['Payback Period (B & 2025)']
        }
        if (grant === '2026') {
            return battery === 'Yes' ? system['Payback Period (NB & 2026)'] : system['Payback Period (B & 2026)']
        }
        return false
    }

    function calculateCost(fetchedData = data) {
        console.log('Calculating cost with:')
        console.log('Panels:', panels)
        console.log('Battery:', battery)
        console.log('Grant:', grant)

        if (!fetchedData) {
            console.error('Data is null or undefined')
            return
        }

        const system = fetchedData.find(row => {
            // Find plan that matches standard plan ID
            const panelsMatch = parseInt(row['Panels']) === parseInt(panels)
            return panelsMatch
        })
        console.log('system: ', system)

        const messages = fetchedData.find(row => {
            return row['Panels'] === 'Message'
        })
        console.log('messages: ', messages)

        let lowerCost = parseFloat(system['Cost'].split(',')[0].replace('€', ''))
        let upperCost = parseFloat(system['Cost'].split(',')[1].replace('€', ''))
        let annualSavings = parseFloat(system['Annual Savings (No Battery)'].replace('€', '').replace(',', ''))
        let lifetimeSavings = parseFloat(system['Lifetime Savings (No Battery)'].replace('€', '').replace(',', ''))
        let paybackPeriod = getPaybackPeriod(system)
        console.log('payback period: ', paybackPeriod)

        let batteryMessage = ''
        let grantMessage = messages[grant]

        if (battery === 'Yes') {
            lowerCost += parseFloat(system['Battery Cost'].split(',')[0].replace('€', ''))
            upperCost += parseFloat(system['Battery Cost'].split(',')[1].replace('€', ''))
            annualSavings = parseFloat(system['Annual Savings (Battery)'].replace('€', '').replace(',', ''))
            lifetimeSavings = parseFloat(system['Lifetime Savings (Battery)'].replace('€', '').replace(',', ''))
            batteryMessage = messages['Battery Cost']
        }

        lowerCost -= parseFloat(system[grant].replace('€', ''))
        upperCost -= parseFloat(system[grant].replace('€', ''))

        setEstimatedCost('€' + lowerCost + ' - ' + '€' + upperCost)
        setAnnualSavings('€' + annualSavings)
        setLifetimeSavings('€' + lifetimeSavings)
        setPaybackPeriod(paybackPeriod)
        setPowerOutput(system['Yearly Power Output'])
        setPanelMessage(system['System Description'])
        setBatteryMessage(batteryMessage)
        setGrantMessage(grantMessage)
        setMessages(messages)
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 220,
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
            },
    }))

    return (
        <form onSubmit={(e) => e.preventDefault()} className='solar-cost-widget'>
            <Grid container justifyContent='center' alignItems='center' style={{padding: '20px'}} spacing={2}>
                <Grid sm={12} md={7} style={{padding: '20px'}}>
                    <Box className='question'>
                        <SliderQuestion
                            question='How many panels do you need?'
                            steps={[4, 8, 10, 12, 14, 16]}
                            answer={panels}
                            updateAnswer={(panels) => setPanels(panels)}
                            site='energyefficiency'
                            label
                            xs={12}
                            sm={12}
                        />
                    </Box>
                    <Box className='question' style={{marginBottom: '1rem'}}>
                        <div className='panel-message'>
                            <p>{panelMessage}</p>
                        </div>
                    </Box>
                    <Box className='question' style={{marginBottom: '1rem', display: 'flex', flexDirection: 'row'}}>
                        <Box style={{width: '50%', padding: '10px'}}>
                            <OptionsQuestion
                                question='Grant application year?'
                                options={[
                                    {value: '2024', label: '2024', disabled: false},
                                    {value: '2025', label: '2025', disabled: false},
                                    {value: '2026', label: '2026', disabled: false}
                                ]}
                                answer={grant}
                                updateAnswer={(grant) => setGrant(grant)}
                                site='energyefficiency'
                                label
                                xs={4}
                                sm={4}
                            />
                            <div className='panel-message'>
                                <p>{grantMessage}</p>
                            </div>
                        </Box>
                        <Box style={{width: '50%', padding: '10px'}}>
                            <OptionsQuestion
                                question='Add a solar battery (5kW)?'
                                options={[
                                    {value: 'No', label: 'No', disabled: false},
                                    {value: 'Yes', label: 'Yes', disabled: false}
                                ]}
                                answer={battery}
                                updateAnswer={(battery) => setBattery(battery)}
                                site='energyefficiency'
                                label
                                xs={4}
                                sm={4}
                            />
                            {batteryMessage &&
                                <div className='panel-message'>
                                    <p>{batteryMessage}</p>
                                </div>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid sm={12} md={5}>
                    {(estimatedCost && powerOutput) &&
                        <Box className='widget-results'>
                            <Grid container spacing={2} justifyContent='center'>
                                <Grid xs={12} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
                                            <p className='widget-cost-heading' style={{ margin: 0, padding: 0 }}>Estimated Installation Cost</p>
                                        </Box>
                                        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
                                            <p className='widget-cost' style={{ margin: 0, padding: 0 }}>
                                                {estimatedCost}
                                            </p>
                                        </Box>
                                </Grid>
                                <Grid xs={12} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <ul style={{ margin: 0, padding: 0 }}>
                                        <li>
                                            Annual electricity savings: <strong>{annualSavings}</strong>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        {battery === 'Yes' ? (
                                                            <p style={{margin: 0, padding: 0}}>Assumes Energia Standard 24hr rate with no discount of 38.08c per kWH / 90% self-consumption of electricity generated and feed-in tariff of 20c per kWh</p>
                                                        ) : (
                                                            <p style={{margin: 0, padding: 0}}>Assumes Energia Standard 24hr rate with no discount of 38.08c per kWH / 60% self-consumption of electricity generated and feed-in tariff of 20c per kWh</p>
                                                        )}
                                                    </React.Fragment>
                                                }
                                                arrow
                                            >
                                                <InfoOutlinedIcon className='info-icon' fontSize='small' />
                                            </HtmlTooltip>
                                        </li>
                                        <li>
                                            Lifetime electricity savings: <strong>{lifetimeSavings}</strong>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <p style={{margin: 0, padding: 0}}>Based off a 25 Year Operational Lifespan</p>
                                                    </React.Fragment>
                                                }
                                                arrow
                                            >
                                                <InfoOutlinedIcon className='info-icon' fontSize='small' />
                                            </HtmlTooltip>
                                        </li>
                                        <li>
                                            Payback period: <strong>{paybackPeriod}</strong>
                                            {grant === '2026' && 
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <p style={{margin: 0, padding: 0}}>Prediction based off €300 grant reduction and similar costs to now</p>
                                                        </React.Fragment>
                                                    }
                                                    arrow
                                                >
                                                    <InfoOutlinedIcon className='info-icon' fontSize='small' />
                                                </HtmlTooltip>
                                            }
                                        </li>
                                        <li>Yearly power output: <strong>{powerOutput}</strong></li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Grid>
            </Grid>
        </form>
    )
}
import Router from './config/routing/Router'

const isLocal = process.env.NODE_ENV === 'development'

// Turn off logs in production
// if (!isLocal) {
//     console.log = function() {}
// }

function App() {
  	return (
    	<div className='App'>
      		<Router />
    	</div>
  	)
}

export default App
export function getColor(site) {
    if (site === 'energyefficiency') {
        return 'energyefficiency'
    }
    else if (site === 'energyswitch') {
        return 'energyswitch'
    }
    else if (site === 'solarfinder') {
        return 'warning'
    }
    else if (site === 'iyi') {
        return 'warning'
    }
    else if (site === 'lifecompare') {
        return 'info'
    }
    else if (site === 'nph') {
        return 'nph'
    }
    else {
        return 'success'
    }
}

export function getPrivacyPolicy(site) {
    if (site === 'energyefficiency') {
        return 'https://energyefficiency.ie/privacy-policy'
    }
    else if (site === 'solarfinder') {
        return 'https://solarfinder.ie/privacy-policy'
    }
    else if (site === 'iyi') {
        return 'https://insureyourincome.ie/privacy-policy'
    }
    else if (site === 'lifecompare') {
        return 'https://lifecompare.ie/privacy-policy'
    }
    else if (site === 'nph') {
        return 'https://nationalpensionhelpline.ie/privacy-policy'
    }
}

export function calculateFormProgress(currentStep, totalSteps, firstYSteps) {
    if (currentStep > totalSteps) {
        // Cap at 100% if the user somehow exceeds the last step
        return 100
    }

    const startProgress = 10
    const endProgressFirstY = 90

    if (currentStep === 0) {
        // Start progress at 10%
        return startProgress
    }

    if (currentStep <= firstYSteps) {
        // Calculate progress for the first Y steps, evenly distributed from 10% to 90%
        const progressPerStep = (endProgressFirstY - startProgress) / firstYSteps
        return startProgress + progressPerStep * currentStep
    } 
    else {
        const remainingSteps = totalSteps - firstYSteps
        const progressAfterFirstY = endProgressFirstY
        const endProgress = 100

        // Calculate how much progress for each step after the first Y steps
        const progressPerRemainingStep = (endProgress - progressAfterFirstY) / remainingSteps

        // Make sure we reach 100% on the last step (currentStep === totalSteps)
        return progressAfterFirstY + (currentStep - firstYSteps) * progressPerRemainingStep
    }
}

export function getAge(dateString) {
    var today = new Date();
    var parts = dateString.split('/');
    // Assuming dateString is in "DD/MM/YYYY"
    var birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function formatNumber(number) {
    const formattedValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number)

    return formattedValue
}

export function solarRedirect(url, provider, hashedName, hashedEmail) {
    const urlWithParams = `${url}?p=${provider}&n=${hashedName}&e=${hashedEmail}`
    window.parent.location.replace(urlWithParams)
}

export function redirect(url, hashedName, hashedEmail) {
    const urlWithParams = `${url}?n=${hashedName}&e=${hashedEmail}`
    window.parent.location.replace(urlWithParams)
}